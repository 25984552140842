<template>
<div style="text-align: left">
  <Card>
    <div class="search-con search-con-top">
      <Input clearable placeholder="输入命令 搜索" class="search-input" v-model="queryinfo.name"
             @on-enter="namechange"
             @on-change="namechange"/>
      <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
      <slot name="new_btn">
        <Button type="primary" class="search-btn"  @click="showaddcmdmodel">添加</Button>
      </slot>
    </div>
    <div>
    <Table border ref="selection" :columns="columns" :data="data" ></Table>
  </div>
    <br />
    <template>
      <Page :current="queryinfo.page"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
    <Modal
          v-model="showaddmodel"
          title="添加命令管理"
          @value="showaddcmdmodel"
          @on-ok="addcmdvalidate"
          width="800px"
          @on-cancel="cancelshowaddmodel">
    <div class="editor-container">
      <Form ref="formValidate" :model="cmddata" :rules="rulecmddata" :label-width="80">
        <FormItem label="名称" prop="name">
          <Input v-model="cmddata.name" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem label="命令" prop="cmd">
          <Input v-model="cmddata.cmd" placeholder="请输入命令"></Input>
        </FormItem>
      </Form>
    </div>
  </Modal>
    <Drawer
          title="修改命令"
          v-model="updatedrawer"
          width="720"
          :mask-closable="false"
          :styles="styles">
    <Form ref="updatecmdref" :model="updatecmdData" :rules="ruleInline">
      <FormItem label="名称" prop="name">
        <Input v-model="updatecmdData.name" placeholder="请输入名称"></Input>
      </FormItem>
      <FormItem label="命令" prop="cmd">
        <Input v-model="updatecmdData.cmd" placeholder="请输入命令"></Input>
      </FormItem>
    </Form>
    <div class="demo-drawer-footer">
      <Button style="margin-right: 8px" @click="updatedrawer = false">关闭</Button>
      <Button type="primary" @click="submit">保存</Button>
    </div>
  </Drawer>
    <Modal
          v-model="showdelete"
          title="删除命令"
          @on-ok="deletecmd(delcmd.id)"
          @on-cancel="canceldelete">
    <p>命令 名称： {{delcmd.name}}</p>
  </Modal>
  </Card>
</div>
</template>

<script>
  import {get,post,put,del} from "@/api/http.js";
  export default {
    name: "index",
    data () {
      return {
        delcmd: {},
        showdelete: false,
        updatedrawer: false,
        cmddata: {},
        updatecmdData: {},
        total: 0,
        pagesizeopts: [10, 30, 50, 100],
        queryinfo: {
          page: 1,
          pagesize: 10,
          name:"",
        },
        ruleInline: {
          name: [
            { required: true, message: '名字不能为空', trigger: 'blur' }
          ],
          cmd: [
            { required: true, message: '命令不能为空', trigger: 'blur' }
          ]
        },
        rulecmddata: {
          name: [
            { required: true, message: '名字不能为空', trigger: 'blur' }
          ],
          cmd: [
            { required: true, message: '命令不能为空', trigger: 'blur' }
          ]
        },
        showaddmodel: false,
        columns: [
          {type: 'selection', width: 60, align: 'center'},
          {title: '名称', key: 'name'}, {title: '命令', key: 'cmd'},
          {title: '操 作', key: 'action', width: 400, align: 'center',
            render: (h, params) => {
              return h('div', [
                h('a', {
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.showupdatedrawer(params.row)
                    }
                  }
                }, '修 改 |'),
                h('a', {
                  on: {
                    click: () => {
                      this.isdelete(params.row)
                    }
                  }
                }, '删 除')
              ]);
            }
          }
        ],
        data:[],
        styles: {
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: '53px',
          position: 'static'
        },
      }
    },
    methods: {
      changepage (page) {
        this.queryinfo.page = page
        this.getdata(this.queryinfo)
      },
      changepagesize(pagesize) {
        this.queryinfo.pagesize = pagesize
        this.getdata(this.queryinfo)
      },
      namechange() {
        this.queryinfo.name = this.queryinfo.name.trim()
        this.queryinfo.page = 1
        this.getdata(this.queryinfo)
      },

      showaddcmdmodel (){
        this.showaddmodel = true
      },
      addcmdvalidate () {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.addcmd(this.cmddata)
          }
        })
      },
      cancelshowaddmodel () {
        this.$Message.info('Clicked cancel');
      },
      addcmd(data) {
        post("/goansible/api/v1/ansible/cmds", data)
          .then((resp)=>{
            this.$Message.info(resp.msg);
            this.queryinfo.page = 1
            this.getdata(this.queryinfo)
          })
          .catch((e)=>{
            console.log(e)
            return e
          })
      },
      showupdatedrawer(row) {
        this.updatedrawer = true
        this.updatecmdData = row
      },
      canceldelete () {},
      updatecmd(id,data) {
        put(`/goansible/api/v1/ansible/cmds?id=`+ id,data)
          .then((resp)=>{
            if (resp.code !== 0){
              this.$Message.error(resp.msg);
            } else {
              this.$Message.info(resp.msg);
              this.updatedrawer = false
            }
          })
          .catch((e)=>{
            return e
          })
      },

      getdata(queryinfo) {
        const url = "/goansible/api/v1/ansible/cmds"
        get(url,queryinfo).then((resp)=>{
          if (resp["code"] === 0){
            this.total = resp["count"]
            this.data = resp["data"]
          } else {
            this.$Message.error("请求失败，请稍后再试")
          }
        })
      },

      submit () {
        this.$refs.updatecmdref.validate((valid) => {
          if (valid) {
            const id = this.updatecmdData.id
            const data = {
              "name":this.updatecmdData.name,
              "cmd":this.updatecmdData.cmd}
            this.updatecmd(id,data)
          }
        })
      },
      deletecmd(id) {
        del(`/goansible/api/v1/ansible/cmds?id=`+ id)
          .then((resp)=>{
            this.queryinfo.page = 1
            this.getdata(this.queryinfo)
          }).catch(e=>{
          return e
        })
      },
      isdelete (row) {
        this.delcmd = row
        this.showdelete = true
      },
    },
    created() {
      this.getdata(this.queryinfo)
    }
  }
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
